import { useEffect, useMemo, useState } from 'react';

import {
  useWebAppInitDataUnsafe,
  useWebAppIsExpanded,
  useWebAppUser,
} from '@kloktunov/react-telegram-webapp';
// eslint-disable-next-line import/no-unresolved
import { TelegramWebApps } from '@kloktunov/react-telegram-webapp/lib/telegram-webapps';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { useLoginWithUserIDMutation } from '@/services/api.service';
import { useI18NStore } from '@/store/hooks/useI18NStore.hook';
import { useUserStore } from '@/store/hooks/useUserStore.hook';

import { appLangFormat } from '@/utils/data.util';
import { isInTMA } from '@/utils/tma-api.util';

import { isLangExistsInEnabledLanguages } from '@/i18n/resourses';

export const useTelegramAuth = () => {
  const initData = useWebAppInitDataUnsafe();
  const user = useWebAppUser();
  const { isExpanded, expand } = useWebAppIsExpanded();

  const { setLang } = useI18NStore();
  const { setJWT, setTGUser, jwt } = useUserStore();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const [userData, setUserData] = useState<
    TelegramWebApps.WebAppInitData | undefined
  >(undefined);
  const [isLoading, setIsLoading] = useState(true);

  const [triggerLogin] = useLoginWithUserIDMutation();

  useEffect(() => {
    const setupUser = () => {
      if (process.env.NODE_ENV === 'development') {
        setUserData({
          query_id: 'AAGxTg4dAAAAALFODh14oJR_',
          user: {
            id: 487476913,
            first_name: 'Yaroslav',
            last_name: 'B',
            username: 'kaylmoran',
            language_code: 'uk',
            is_bot: false,
          },
          auth_date: 1708332437,
          hash: '86751cfc7df2400302408e670d202d098da9716914a835b1d685eea3164b7887',
        });
        // setLang('ar'); // testing feature
      } else {
        if (initData) {
          setUserData(initData);
          setLang(
            isLangExistsInEnabledLanguages(initData?.user?.language_code)
              ? appLangFormat(initData?.user?.language_code)
              : 'en',
          );
        }
      }
    };

    if (isInTMA()) {
      setupUser();
    }
  }, [initData, setLang, user]);

  useEffect(() => {
    if (!isExpanded && isInTMA()) {
      expand();
    }
  }, [expand, isExpanded]);

  useEffect(() => {
    const handleLogin = async () => {
      try {
        const data = await triggerLogin({
          init_data: userData,
          dirty_params: window.location.href,
        }).unwrap();

        setTGUser(userData?.user);
        setJWT(data?.token);

        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
      }
    };

    if (userData && !jwt && executeRecaptcha && isInTMA()) {
      handleLogin();
    }
  }, [
    executeRecaptcha,
    initData,
    jwt,
    setJWT,
    setTGUser,
    triggerLogin,
    user,
    userData,
  ]);

  return { isLoading };
};
